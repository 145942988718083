import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericResponse } from 'src/app/models/shared/generic-response.model';
import { API } from 'src/app/shared/app-constant';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FcSiteInspectionReportService {
  private _data: any = '';
  constructor(private http: HttpClient) {}

  deleteLegalStatus(id: any): Observable<GenericResponse> {
    let url = environment.baseURL + API.DELETE_SIR_LEGAL_STATUS;
    let param = new HttpParams().append('id', id);
    return this.http.post<any>(url, null, { params: param });
  }
  
  deleteQuarry(id: any): Observable<GenericResponse> {
    let url = environment.baseURL + API.DELETE_SIR_QUARRY;
    let param = new HttpParams().append('id', id);
    return this.http.post<any>(url, null, { params: param });
  }
  
  deleteSpecificComponent(id: any): Observable<GenericResponse> {
    let url = environment.baseURL + API.DELETE_SIR_SPECIFIC_COMPONENT;
    let param = new HttpParams().append('id', id);
    return this.http.post<any>(url, null, { params: param });
  }
  
  deleteUndertakingDetails(id: any): Observable<GenericResponse> {
    let url = environment.baseURL + API.DELETE_SIR_UNDERTAKING;
    let param = new HttpParams().append('id', id);
    return this.http.post<any>(url, null, { params: param });
  }

  deleteComponentWiseDetails(id: any): Observable<GenericResponse> {
    let url = environment.baseURL + API.DELETE_SIR_COMPONENT_WISE_DETAILS;
    let param = new HttpParams().append('id', id);
    return this.http.post<any>(url, null, { params: param });
  }
  
  deleteSpeciesDetails(id: any): Observable<GenericResponse> {
    let url = environment.baseURL + API.DELETE_SIR_SPECIES_DETAILS;
    let param = new HttpParams().append('id', id);
    return this.http.post<any>(url, null, { params: param });
  }

  deleteOfficialDetails(id: any): Observable<GenericResponse> {
    let url = environment.baseURL + API.DELETE_SIR_COMPONENT_WISE_DETAILS;
    let param = new HttpParams().append('id', id);
    return this.http.post<any>(url, null, { params: param });
  }

  saveBasicDetails(clearanceId: any, body: any): Observable<GenericResponse> {
    let url = environment.baseURL + API.SAVE_SIR_BASIC_DETAILS;
    let param = new HttpParams().append('clearanceId', clearanceId);
    return this.http.post<any>(url, body, { params: param });
  }
  
  saveOtherDetails(id: any, body: any): Observable<GenericResponse> {
    let url = environment.baseURL + API.SAVE_SIR_OTHER_DETAILS;
    let param = new HttpParams().append('basicDetailsId', id);
    return this.http.post<any>(url, body, { params: param });
  }
  saveQuarryDetails(id: any, body: any): Observable<GenericResponse> {
    let url = environment.baseURL + API.SAVE_SIR_QUARRY_DETAILS;
    let param = new HttpParams().append('basicDetailsId', id);
    return this.http.post<any>(url, body, { params: param });
  }
  saveSpecificComponentDetails(id: any, body: any): Observable<GenericResponse> {
    let url = environment.baseURL + API.SAVE_SIR_SPECIFIC_COMPONENT_DETAILS;
    let param = new HttpParams().append('basicDetailsId', id);
    return this.http.post<any>(url, body, { params: param });
  }

  saveUndertaking(id: any, body: any): Observable<GenericResponse> {
    let url = environment.baseURL + API.SAVE_SIR_UNDERTAKING;
    let param = new HttpParams().append('basicDetailsId', id);
    return this.http.post<any>(url, body, { params: param });
  }
 
  getDetails(id: any): Observable<GenericResponse> {
    let url = environment.baseURL + API.GET_SIR_DETAILS;
    let param = new HttpParams().append('id', id);
    return this.http.post<any>(url, null, { params: param });
  }

  setEsignedPath(proposalNo:any,siteInspectionBasicDetailsId:any,filepath:any){
    console.log("checkin",filepath,proposalNo,siteInspectionBasicDetailsId);
    const url = `${environment.baseURL}${API.SET_ESIGNED_SIR_PATH}?filePath=${filepath}&proposalNo=${proposalNo}&siteInspectionBasicDetailsId=${siteInspectionBasicDetailsId}`;
    console.log("URL",url);
  
    return this.http.post<any>(url,{})

    // return this.http.post<any>(url, {}).subscribe(
    //   (response) => {
    //     console.log('Request successful', response);
    //   },
    //   (error) => {
    //     console.error('Request failed', error);
    //   }
  }

  setData(data:any){
    sessionStorage.setItem('fPath',JSON.stringify(data));
    let a = localStorage.setItem('aPath',JSON.stringify(data));
    this._data = data;
    console.log("data from see service",a,data);
   // this.setEsignedPath(this._data?.data,)
    
  }

  getData(){
    console.log("data called",sessionStorage.getItem('fPath'),localStorage.getItem('aPath'));
    let data = sessionStorage.getItem('fPath');
    //let data = localStorage.getItem('aPath')
  
  if (!data) {
    data = localStorage.getItem('aPath');
   let sData = sessionStorage.getItem('fPath');
   console.log("SData",sData);
   
  }
  
  return data ? JSON.parse(data) : null;
  }
}
