<div class="header-logo py-2">
  <div class="{{ fluid ? 'container-fluid' : 'container-fluid' }}">
    <div class="row align-items-center">
      <div class="col col-md">
        <a routerLink="/home">
          <img
            width="500"
            src="./assets/images/logo-new.svg"
            class="img-fluid"
            alt="parivesh moefcc"
          />
        </a>
      </div>
      <div class="col col-md-auto">
        <img
          src="./assets/images/gov-logo-new.svg"
          class="img-fluid"
          alt="moefcc"
        />
      </div>
    </div>
   
    <ng-container *ngIf="userRole$ | async as userRole">
      <div
        class="row"
        *ngIf="(userRole?.data?.role || userRole?.data?.designation)"
      >
        <div class="col text-end">
          <span><small>Welcome, </small></span>
          <span *ngIf="userName$ | async as user"
            ><strong *ngIf="user?.name_of_Entity == null && user?.name"
              ><small>{{ user?.name | titlecase }}</small></strong
            >
            <strong *ngIf="user?.name_of_Entity"
              ><small>{{ user?.name_of_Entity | titlecase }}</small></strong
            >
            </span
          >
          <span>
            <small>
              (
              <span *ngIf="userRole?.data?.designation"
                ><span
                *ngIf="
                  !userRole?.data?.role.includes(
                  userRole?.data?.designation?.trim()
                  )
                "
                > <strong>Designation:</strong>  {{ userRole?.data.designation }},&nbsp;</span
                > </span
              > <strong>Role:</strong> {{ userRole?.data?.role }}<span *ngIf="userRole?.data?.role == 'Project Proponent'">&nbsp;
                <strong class="text-danger">(Super User{{is_three_tier_hierarcy ? ' - HQ)' : ')'}}</strong>
                </span><span *ngIf="userRole?.data?.jurisdiction">,&nbsp;
              <strong>Jurisdiction:</strong> {{ formatJurisdiction(userRole?.data?.jurisdiction) | titlecase }}
              </span>&nbsp;)
              </small>
          </span>
          <span><button class="btn btn-sm btn-secondary d-inline-flex align-items-center" (click)="logout()">Logout</button></span>
        </div>
      </div>
    </ng-container>
  </div>
</div>
