import {AUTH_TOKEN_EXPIRED, WSO2_TOKEN_EXPIRY_DATETIME_KEY, WSO2_TOKEN_EXPIRY_TIME_KEY, WSO2_TOKEN_KEY, WSO2_TOKEN_WAITING_SINCE_KEY,} from './shared/app-constant';
import {Component, Injector} from '@angular/core';
import {fromEvent, mapTo, merge, Observable, of} from 'rxjs';

import {AuthService} from './services/auth.service';
import {BroadcastService} from './services/broadcaster.service';
import {LoaderService} from './services/loader.service';
import {Router} from '@angular/router';
import {SessionStorageService} from './services/session-storage.service';
import {Wso2Service} from './services/wso2.service';
import {environment} from 'src/environments/environment';
import {isEmpty} from 'lodash';

export let AppInjector: Injector;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  online$: Observable<boolean>;
  onlineStatus!: boolean;
  showDiv!: boolean;
  onLineMessage = '';
  environment = environment;

  constructor(
    public loaderService: LoaderService,
    public wso2Service: Wso2Service,
    public sessionStorageService: SessionStorageService,
    public authService: AuthService,
    private broadCastService: BroadcastService,
    private router: Router,
    private injector: Injector,
  ) {
    AppInjector = this.injector;
    // this.getWso2Token();

    this.broadCastService.on(AUTH_TOKEN_EXPIRED).subscribe(() => {
      this.refreshAuthToken();
    });

    this.online$ = merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(mapTo(true)),
      fromEvent(window, 'offline').pipe(mapTo(false))
    );

    this.online$.subscribe((res) => {
      this.onlineStatus = res;
      if (this.onlineStatus) {
        this.onLineMessage = 'welcome back! you are online';
        setTimeout(() => {
          this.showDiv = false;
        }, 5000);
      } else {
        this.onLineMessage =
          'Please check your internet connection, then try again!';
        this.showDiv = true;
      }
    });
  }

  //Get API Token function
  public getWso2Token() {
    if (environment.wso2) {
      this.wso2Service.getToken().subscribe({
        next: (response) => {
          let currentTime = new Date();
          currentTime.setMinutes(currentTime.getMinutes() + 50);

          this.sessionStorageService.set(
            WSO2_TOKEN_EXPIRY_TIME_KEY,
            response.expires_in - 100
          );

          this.sessionStorageService.set(
            WSO2_TOKEN_EXPIRY_DATETIME_KEY,
            currentTime
          );

          this.sessionStorageService.set(
            WSO2_TOKEN_WAITING_SINCE_KEY,
            '' + new Date().getTime()
          );

          if (isEmpty(this.sessionStorageService.get(WSO2_TOKEN_KEY))) {
            setInterval(() => {
              if (this.wso2Service.isTokenExpired()) {
                this.getWso2Token();
              }
            }, 600000);
            this.router.navigate(['home']);
          }

          this.sessionStorageService.set(
            WSO2_TOKEN_KEY,
            `Bearer ${response.access_token}`
          );
        },
        error: (err) => {
          console.log(err);
        },
      });
    }
  }

  public refreshAuthToken() {
    this.authService
      .refreshToken({
        refreshToken: this.authService.REFRESH_TOKEN,
      })
      .subscribe({
        next: (response) => { },
        error: (err) => {
          console.log(err);
        },
      });
  }
}
