import { RouterModule, Routes } from '@angular/router';

import { ActivationPageComponent } from './components/user-registration/activation-page/activation-page.component';
import { ApiGuard } from './gaurds/api.gaurd';
import { AuthGuard } from './gaurds/auth.gaurd';
import { ErrorNotFoundComponent } from './components/error-not-found/error-not-found.component';
import { NgModule } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BaseLayoutComponent } from './layouts/base-layout/base-layout.component';
import { UpdateKycComponent } from './components/user-registration/update-kyc/update-kyc.component';
import { VerificationSuccessfullComponent } from './shared/components/verification-successfull/verification-successfull.component';
import { AuthResolver } from './state/auth-state/auth.resolver';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';

const routesProd: Routes = [
  {
    path: 'home',
    canActivate: [ApiGuard],
    loadChildren: () =>
      import('src/app/components/home-legacy/home-legacy.module').then(
        (m) => m.HomeLegacyModule
      ),
  },
  {
    path: 'organisation',
    canActivate: [ApiGuard],
    data: { title: 'Home' },
    resolve: {
      user: AuthResolver,
    },
    loadChildren: () =>
      import('src/app/components/organisation/organisation.module').then(
        (m) => m.OrganisationModule
      ),
  },
  {
    path: 'user',
    canActivate: [ApiGuard, AuthGuard],
    loadChildren: () =>
      import('src/app/components/user/user.module').then((m) => m.UserModule),
  },

  {
    path: 'legal-ngt-form',
    canActivate: [ApiGuard, AuthGuard],
    component: AdminLayoutComponent,
    loadChildren: () =>
      import(
        'src/app/components/legal/legal-ngt-form/legal-ngt-form.module'
      ).then((m) => m.LegalNgtFormModule),
  },
  {
    path: 'legal-ngt-form-list',
    component: AdminLayoutComponent,
    canActivate: [ApiGuard, AuthGuard],
    loadChildren: () =>
      import(
        'src/app/components/legal/legal-ngt-form-list/legal-ngt-form-list.module'
      ).then((m) => m.LegalNgtFormListModule),
  },
  {
    path: 'legal-associate',
    component: AdminLayoutComponent,
    canActivate: [ApiGuard, AuthGuard],
    loadChildren: () =>
      import(
        'src/app/components/legal-associate/legal-associate.module'
      ).then((m) => m.LegalAssociateModule),
  },
  {
    path: 'department',
    canActivate: [ApiGuard],
    resolve: {
      user: AuthResolver,
    },
    loadChildren: () =>
      import('src/app/components/department/department.module').then(
        (m) => m.DepartmentModule
      ),
  },
  {
    path: 'print',
    canActivate: [ApiGuard],
    loadChildren: () =>
      import('src/app/components/print/print.module').then(
        (m) => m.PrintModule
      ),
  },
  {
    path: 'userRegistration',
    canActivate: [ApiGuard],
    loadChildren: () =>
      import(
        'src/app/components/user-registration/user-registration.module'
      ).then((m) => m.UserRegistrationModule),
  },
  {
    path: 'GenCode',
    canActivate: [ApiGuard, AuthGuard],
    loadChildren: () =>
      import('src/app/components/gen-code-master/gen-code.module').then(
        (m) => m.GenCodeModule
      ),
  },
  {
    path: 'trackYourProposal',
    component: BaseLayoutComponent,
    canActivate: [ApiGuard],
    loadChildren: () =>
      import(
        'src/app/components/track-your-proposal/track-your-proposal.module'
      ).then((m) => m.TrackYourProposalModule),
  },
  {
    path: 'legal-login',
    component: BaseLayoutComponent,
    canActivate: [ApiGuard],
    loadChildren: () =>
      import('src/app/components/legal-login/legal-login.module').then(
        (m) => m.LegalLoginModule
      ),
  },
  {
    path: 'legal-signup',
    component: BaseLayoutComponent,
    canActivate: [ApiGuard],
    loadChildren: () =>
      import('src/app/components/legal-signup/legal-signup.module').then(
        (m) => m.LegalSignupModule
      ),
  },
  {
    path: 'legal-vacancies',
    component: BaseLayoutComponent,
    canActivate: [ApiGuard],
    loadChildren: () =>
      import(
        'src/app/components/legal-intermediate-login/legal-intermediate-login.module'
      ).then((m) => m.LegalIntermediateLoginModule),
  },
  {
    path: 'trackYourProposalLegacy',
    component: BaseLayoutComponent,
    canActivate: [ApiGuard],
    loadChildren: () =>
      import(
        'src/app/components/track-your-proposal-legacy/track-your-proposal-legacy.module'
      ).then((m) => m.TrackYourProposalLegacyModule),
  },
  {
    path: 'trackYourProposalWLC',
    component: BaseLayoutComponent,
    canActivate: [ApiGuard],
    loadChildren: () =>
      import(
        'src/app/components/track-your-proposal-wlc/track-your-proposal-wlc.module'
      ).then((m) => m.TrackYourProposalWlcModule),
  },
  {
    path: 'report',
    canActivate: [ApiGuard],
    resolve: {
      user: AuthResolver,
    },
    loadChildren: () =>
      import('src/app/components/report/report.module').then(
        (m) => m.ReportModule
      ),
  },
  {
    path: 'legacy-report',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('src/app/components/legacy-report/legacy-report.module').then(
        (m) => m.LegacyReportModule
      ),
  },
  {
    path: 'digital-signature',
    canActivate: [ApiGuard, AuthGuard],
    loadChildren: () =>
      import(
        'src/app/components/digital-signature/digital-signature.module'
      ).then((m) => m.DigitalSignatureModule),
  },
  { path: 'activateEmail', component: ActivationPageComponent },
  { path: 'updateKyc', component: UpdateKycComponent },
  {
    path: 'payment-gateway',
    canActivate: [ApiGuard, AuthGuard],
    loadChildren: () =>
      import('src/app/components/payment-gateway/payment-gateway.module').then(
        (m) => m.PaymentGatewayModule
      ),
  },
  {
    path: 'fc',
    canActivate: [ApiGuard],
    loadChildren: () =>
      import('src/app/components/report/fc-a-part/fc-a-part.module').then(
        (m) => m.FcAPartModule
      ),
  },
  {
    path: 'fc-part-d',
    canActivate: [ApiGuard],
    loadChildren: () =>
      import('src/app/components/report/fc-d-report/fc-d-report.module').then(
        (m) => m.FcDReportModule
      ),
  },
  {
    path: 'ec-fact-sheet',
    canActivate: [ApiGuard],
    loadChildren: () =>
      import(
        'src/app/components/report/ec-fact-sheet/ec-fact-sheet.module'
      ).then((m) => m.EcFactSheetModule),
  },
  {
    path: 'fc-part-b',
    canActivate: [ApiGuard],
    loadChildren: () =>
      import('src/app/components/report/fc-b-part/fc-b-part.module').then(
        (m) => m.FcBPartModule
      ),
  },
  {
    path: 'fc-part-c',
    canActivate: [ApiGuard],
    loadChildren: () =>
      import('src/app/components/report/fc-c-part/fc-c-part.module').then(
        (m) => m.FcCPartModule
      ),
  },
  {
    path: 'fc-part-e',
    canActivate: [ApiGuard],
    loadChildren: () =>
      import('src/app/components/report/fc-e-part/fc-e-part.module').then(
        (m) => m.FcEPartModule
      ),
  },
  {
    path: 'ec-part-b',
    canActivate: [ApiGuard],
    loadChildren: () =>
      import('src/app/components/report/ec-b-part/ec-b-part.module').then(
        (m) => m.EcBPartModule
      ),
  },
  {
    path: 'ec-part-c',
    canActivate: [ApiGuard],
    loadChildren: () =>
      import('src/app/components/report/ec-c-part/ec-c-part.module').then(
        (m) => m.EcCPartModule
      ),
  },
  {
    path: 'ec-form-five',
    canActivate: [ApiGuard],
    loadChildren: () =>
      import(
        'src/app/components/report/ec-form-five-report/ec-form-five-report.module'
      ).then((m) => m.EcFormFiveReportModule),
  },
  {
    path: 'ec-part-a',
    canActivate: [ApiGuard],
    loadChildren: () =>
      import('src/app/components/report/ec-a-part/ec-a-part.module').then(
        (m) => m.EcAPartModule
      ),
  },
  {
    path: 'ec-form-eight',
    canActivate: [ApiGuard],
    loadChildren: () =>
      import(
        'src/app/components/report/ec-form-eight-report/ec-form-eight-report.module'
      ).then((m) => m.EcFormEightReportModule),
  },
  {
    path: 'ec-form-six',
    canActivate: [ApiGuard],
    loadChildren: () =>
      import(
        'src/app/components/report/ec-form-six-report/ec-form-six-report.module'
      ).then((m) => m.EcFormSixReportModule),
  },
  {
    path: 'ec-form-ten',
    canActivate: [ApiGuard],
    loadChildren: () =>
      import(
        'src/app/components/report/ec-form-ten-report/ec-form-ten-report.module'
      ).then((m) => m.EcFormTenReportModule),
  },
  {
    path: 'ec-form-nine',
    canActivate: [ApiGuard],
    loadChildren: () =>
      import(
        'src/app/components/report/ec-form-nine-report/ec-form-nine-module'
      ).then((m) => m.EcFormNineReportModule),
  },
  {
    path: 'ec-form-seven',
    canActivate: [ApiGuard],
    loadChildren: () =>
      import(
        'src/app/components/report/ec-form-seven-report/ec-form-seven-report.module'
      ).then((m) => m.EcFormSevenReportModule),
  },
  {
    path: 'certificate',
    canActivate: [ApiGuard],
    resolve: {
      user: AuthResolver,
    },
    loadChildren: () =>
      import('src/app/components/certificates/certificates.module').then(
        (m) => m.CertificatesModule
      ),
  },
  {
    path: 'caf',
    canActivate: [ApiGuard],
    loadChildren: () =>
      import('src/app/components/report/cafreport/cafreport.module').then(
        (m) => m.CafreportModule
      ),
  },
  {
    path: 'wl',
    canActivate: [ApiGuard],
    loadChildren: () =>
      import('src/app/components/report/wl-report/wl-report.module').then(
        (m) => m.WlReportModule
      ),
  },
  {
    path: 'crz',
    canActivate: [ApiGuard],
    loadChildren: () =>
      import('src/app/components/report/crz-report/crz-report.module').then(
        (m) => m.CrzReportModule
      ),
  },
  {
    path: 'campa',
    loadChildren: () =>
      import('../app/components/campa/campa.module').then((m) => m.CampaModule),
  },

  {
    path: 'organisation/wl/exotic-species',
    component: AdminLayoutComponent,
    loadChildren: () =>
      import('src/app/components/exotic-species/exotic-species.module').then(
        (m) => m.ExoticSpeciesModule
      ),
  },
  {
    path: 'organisation/wl/exotic-esign',
    loadChildren: () =>
      import(
        'src/app/components/certificates/exotic-esign/exotic-esign.module'
      ).then((m) => m.ExoticEsignModule),
  },
  {
    path: 'exotic-species-preview',
    loadChildren: () =>
      import(
        'src/app/components/report/exotic-species-preview/exotic-species-preview.module'
      ).then((m) => m.ExoticSpeciesPreviewModule),
  },

  {
    path: 'organisation/wl/exotic-species-proposal',
    component: AdminLayoutComponent,
    loadChildren: () =>
      import(
        'src/app/components/exotic-species-proposal/exotic-species-proposal.module'
      ).then((m) => m.ExoticSpeciesProposalModule),
  },
  {
    path: 'proposal-summary',
    canActivate: [ApiGuard],
    loadChildren: () =>
      import(
        'src/app/components/proposal-summary/proposal-summary.module'
      ).then((m) => m.ProposalSummaryModule),
  },
  {
    path: 'payment-details-dfo',
    canActivate: [ApiGuard],
    loadChildren: () =>
      import(
        'src/app/components/report/payment-details-dfo-report/payment-details-dfo-report.module'
      ).then((m) => m.PaymentDetailsDfoReportModule),
  },
  {
    path: 'payment-completion-details',
    canActivate: [ApiGuard],
    loadChildren: () =>
      import(
        'src/app/components/report/payment-completion-details-report/payment-completion-details-report.module'
      ).then((m) => m.PaymentCompletionDetailsReportModule),
  },
  {
    path: 'fc-a-part-b',
    canActivate: [ApiGuard],
    loadChildren: () =>
      import(
        'src/app/components/report/fc-process-flow-report/fc-a-partb/fc-a-partb.module'
      ).then((m) => m.FcAPartbModule),
  },
  {
    path: 'fc-b-partb',
    canActivate: [ApiGuard],
    loadChildren: () =>
      import(
        'src/app/components/report/fc-process-flow-report/fc-b-partb/fc-b-partb.module'
      ).then((m) => m.FcBPartbModule),
  },
  {
    path: 'fc-c-partb',
    canActivate: [ApiGuard],
    loadChildren: () =>
      import(
        'src/app/components/report/fc-process-flow-report/fc-c-partb/fc-c-partb.module'
      ).then((m) => m.FcCPartbModule),
  },
  {
    path: 'fc-d-partb',
    canActivate: [ApiGuard],
    loadChildren: () =>
      import(
        'src/app/components/report/fc-process-flow-report/fc-d-partb/fc-d-partb.module'
      ).then((m) => m.FcDPartbModule),
  },
  {
    path: 'fc-e-partb',
    canActivate: [ApiGuard],
    loadChildren: () =>
      import(
        'src/app/components/report/fc-process-flow-report/fc-e-partb/fc-e-partb.module'
      ).then((m) => m.FcEPartbModule),
  },
  {
    path: 'eds-form',
    canActivate: [ApiGuard],
    loadChildren: () =>
      import(
        'src/app/components/report/fc-process-flow-report/eds-form/eds-form.module'
      ).then((m) => m.EdsFormModule),
  },
  {
    path: 'remarks-form',
    canActivate: [ApiGuard],
    loadChildren: () =>
      import(
        'src/app/components/report/fc-process-flow-report/recommendation/recommendation.module'
      ).then((m) => m.RecommendationModule),
  },
  {
    path: 'dc-form',
    canActivate: [ApiGuard],
    loadChildren: () =>
      import(
        'src/app/components/report/fc-process-flow-report/dc-form/dc-form.module'
      ).then((m) => m.DcFormModule),
  },
  {
    path: 'cms',
    canActivate: [ApiGuard],
    data: { title: 'Home' },
    loadChildren: () =>
      import('src/app/components/cms/cms.module').then((m) => m.CmsModule),
  },
  {
    path: 'digital-signature',
    canActivate: [ApiGuard, AuthGuard],
    loadChildren: () =>
      import(
        'src/app/components/digital-signature/digital-signature.module'
      ).then((m) => m.DigitalSignatureModule),
  },
  {
    path: 'legal-ngt-form-submit-success',
    component: AdminLayoutComponent,
    canActivate: [ApiGuard, AuthGuard],
    loadChildren: () =>
      import(
        'src/app/components/legal/legal-ngt-form-submit-success/legal-ngt-form-submit-success.module'
      ).then((m) => m.LegalNgtFormSubmitSuccessModule),
  },
  {
    path: 'aadhar-sign',
    canActivate: [ApiGuard],
    data: { title: 'Aadhar Sign' },
    loadChildren: () =>
      import('src/app/components/aadhar-sign/aadhar-sign.module').then(
        (m) => m.AadharSignModule
      ),
  },
  {
    path: 'aco',
    canActivate: [ApiGuard],
    loadChildren: () =>
      import('src/app/components/aco/aco.module').then((m) => m.AcoModule),
  },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'error-404', component: ErrorNotFoundComponent },
  { path: 'error-403', component: ErrorNotFoundComponent },
  {
    path: '**',
    redirectTo: 'error-404',
    pathMatch: 'full',
  },
];

const routesDev: Routes = [
  {
    path: 'home',
    canActivate: [ApiGuard],
    loadChildren: () =>
      import('src/app/components/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'user',
    canActivate: [ApiGuard, AuthGuard],
    loadChildren: () =>
      import('src/app/components/user/user.module').then((m) => m.UserModule),
  },
  {
    path: 'organisation',
    canActivate: [ApiGuard],
    resolve: {
      user: AuthResolver,
    },
    loadChildren: () =>
      import('src/app/components/organisation/organisation.module').then(
        (m) => m.OrganisationModule
      ),
  },
  {
    path: 'aco',
    canActivate: [ApiGuard],
    loadChildren: () =>
      import('src/app/components/aco/aco.module').then((m) => m.AcoModule),
  },
  {
    path: 'cms',
    canActivate: [ApiGuard],
    data: { title: 'Home' },
    loadChildren: () =>
      import('src/app/components/cms/cms.module').then((m) => m.CmsModule),
  },
  {
    path: 'campa',
    loadChildren: () =>
      import('../app/components/campa/campa.module').then((m) => m.CampaModule),
  },

  {
    path: 'organisation/wl/exotic-species',
    component: AdminLayoutComponent,
    loadChildren: () =>
      import('src/app/components/exotic-species/exotic-species.module').then(
        (m) => m.ExoticSpeciesModule
      ),
  },

  {
    path: 'organisation/wl/exotic-esign',
    loadChildren: () =>
      import(
        'src/app/components/certificates/exotic-esign/exotic-esign.module'
      ).then((m) => m.ExoticEsignModule),
  },
  {
    path: 'exotic-species-preview',
    loadChildren: () =>
      import(
        'src/app/components/report/exotic-species-preview/exotic-species-preview.module'
      ).then((m) => m.ExoticSpeciesPreviewModule),
  },
  {
    path: 'organisation/wl/exotic-species-proposal',
    component: AdminLayoutComponent,
    loadChildren: () =>
      import(
        'src/app/components/exotic-species-proposal/exotic-species-proposal.module'
      ).then((m) => m.ExoticSpeciesProposalModule),
  },
  {
    path: 'department',
    canActivate: [ApiGuard],
    resolve: {
      user: AuthResolver,
    },
    loadChildren: () =>
      import('src/app/components/department/department.module').then(
        (m) => m.DepartmentModule
      ),
  },
  {
    path: 'print',
    canActivate: [ApiGuard],
    loadChildren: () =>
      import('src/app/components/print/print.module').then(
        (m) => m.PrintModule
      ),
  },
  {
    path: 'proposal-summary',
    canActivate: [ApiGuard],
    data: { title: 'Proposal Summary' },
    loadChildren: () =>
      import(
        'src/app/components/proposal-summary/proposal-summary.module'
      ).then((m) => m.ProposalSummaryModule),
  },
  {
    path: 'userRegistration',
    canActivate: [ApiGuard],
    loadChildren: () =>
      import(
        'src/app/components/user-registration/user-registration.module'
      ).then((m) => m.UserRegistrationModule),
  },
  {
    path: 'GenCode',
    canActivate: [ApiGuard, AuthGuard],
    loadChildren: () =>
      import('src/app/components/gen-code-master/gen-code.module').then(
        (m) => m.GenCodeModule
      ),
  },
  {
    path: 'trackYourProposal',
    component: BaseLayoutComponent,
    canActivate: [ApiGuard],
    loadChildren: () =>
      import(
        'src/app/components/track-your-proposal/track-your-proposal.module'
      ).then((m) => m.TrackYourProposalModule),
  },
  {
    path: 'trackYourProposalLegacy',
    component: BaseLayoutComponent,
    canActivate: [ApiGuard],
    loadChildren: () =>
      import(
        'src/app/components/track-your-proposal-legacy/track-your-proposal-legacy.module'
      ).then((m) => m.TrackYourProposalLegacyModule),
  },
  {
    path: 'trackYourProposalWLC',
    component: BaseLayoutComponent,
    canActivate: [ApiGuard],
    loadChildren: () =>
      import(
        'src/app/components/track-your-proposal-wlc/track-your-proposal-wlc.module'
      ).then((m) => m.TrackYourProposalWlcModule),
  },
  {
    path: 'legal-login',
    component: BaseLayoutComponent,
    // canActivate: [ApiGuard],
    loadChildren: () =>
      import('src/app/components/legal-login/legal-login.module').then(
        (m) => m.LegalLoginModule
      ),
  },
  {
    path: 'legal-signup',
    component: BaseLayoutComponent,
    // canActivate: [ApiGuard],
    loadChildren: () =>
      import('src/app/components/legal-signup/legal-signup.module').then(
        (m) => m.LegalSignupModule
      ),
  },
  {
    path: 'legal-vacancies',
    component: BaseLayoutComponent,
    // canActivate: [ApiGuard],
    loadChildren: () =>
      import(
        'src/app/components/legal-intermediate-login/legal-intermediate-login.module'
      ).then((m) => m.LegalIntermediateLoginModule),
  },
  {
    path: 'legal-ngt-form',
    component: AdminLayoutComponent,
    // canActivate: [ApiGuard, AuthGuard],
    loadChildren: () =>
      import(
        'src/app/components/legal/legal-ngt-form/legal-ngt-form.module'
      ).then((m) => m.LegalNgtFormModule),
  },
  {
    path: 'legal-associate',
    component: AdminLayoutComponent,
    // canActivate: [ApiGuard, AuthGuard],
    loadChildren: () =>
      import(
        'src/app/components/legal-associate/legal-associate.module'
      ).then((m) => m.LegalAssociateModule),
  },
  {
    path: 'legal-ngt-form-submit-success',
    component: AdminLayoutComponent,
    // canActivate: [ApiGuard, AuthGuard],
    loadChildren: () =>
      import(
        'src/app/components/legal/legal-ngt-form-submit-success/legal-ngt-form-submit-success.module'
      ).then((m) => m.LegalNgtFormSubmitSuccessModule),
  },
  {
    path: 'legal-ngt-form-list',
    component: AdminLayoutComponent,
    // canActivate: [ApiGuard, AuthGuard],
    loadChildren: () =>
      import(
        'src/app/components/legal/legal-ngt-form-list/legal-ngt-form-list.module'
      ).then((m) => m.LegalNgtFormListModule),
  },
  {
    path: 'report',
    canActivate: [ApiGuard],
    resolve: {
      user: AuthResolver,
    },
    loadChildren: () =>
      import('src/app/components/report/report.module').then(
        (m) => m.ReportModule
      ),
  },
  {
    path: 'certificate',
    canActivate: [ApiGuard],
    resolve: {
      user: AuthResolver,
    },
    loadChildren: () =>
      import('src/app/components/certificates/certificates.module').then(
        (m) => m.CertificatesModule
      ),
  },
  {
    path: 'legacy-report',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('src/app/components/legacy-report/legacy-report.module').then(
        (m) => m.LegacyReportModule
      ),
  },
  {
    path: 'payment-gateway',
    canActivate: [ApiGuard, AuthGuard],
    loadChildren: () =>
      import('src/app/components/payment-gateway/payment-gateway.module').then(
        (m) => m.PaymentGatewayModule
      ),
  },
  {
    path: 'eds-response-crz',
    canActivate: [ApiGuard, AuthGuard],
    data: { title: 'EDS Response' },
    loadChildren: () =>
      import(
        'src/app/components/eds-query-response-crz/eds-query-response-crz.module'
      ).then((m) => m.EdsQueryResponseCrzModule),
  },
  {
    path: 'legacy-report',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('src/app/components/legacy-report/legacy-report.module').then(
        (m) => m.LegacyReportModule
      ),
  },
  {
    path: 'digital-signature',
    canActivate: [ApiGuard, AuthGuard],
    loadChildren: () =>
      import(
        'src/app/components/digital-signature/digital-signature.module'
      ).then((m) => m.DigitalSignatureModule),
  },
  {
    path: 'aadhar-sign',
    canActivate: [ApiGuard],
    data: { title: 'Aadhar Sign' },
    loadChildren: () =>
      import('src/app/components/aadhar-sign/aadhar-sign.module').then(
        (m) => m.AadharSignModule
      ),
  },
  { path: 'activateEmail', component: ActivationPageComponent },
  { path: 'updateKyc', component: UpdateKycComponent },
  {
    path: 'verification-successfull',
    component: VerificationSuccessfullComponent,
  },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'error-404', component: ErrorNotFoundComponent },
  { path: 'error-403', component: ErrorNotFoundComponent },
  // {
  //   path: '**',
  //   redirectTo: 'error-404',
  //   pathMatch: 'full',
  // },
];

@NgModule({
  imports: [
    RouterModule.forRoot(environment.production ? routesProd : routesDev, {
      useHash: true,
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
