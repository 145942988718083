<div class="sidebar-menu d-flex flex-column justify-content-between h-100">
  <div class="sidebar-menu-inner mt-3">
    <div class="sidebar-menu-list">
      <ng-container *ngFor="let menu of menues">
        <a
          [routerLink]="menu.route"
          [queryParams]="menu?.queryParams"
          class="sidebar-menu-item"
          [routerLinkActive]="['active']"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="!menu.legacy"
        >
          <span class="sidebar-menu-icon">
            <svg height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <use attr.xlink:href="{{ menu.icon }}" />
            </svg>
          </span>
          <span
            *ngIf="menu?.label != 'Dispatch Module'"
            class="sidebar-menu-text"
            >{{
              menu.label == "NGT Form" ? "NGT Form" : menu.label
            }}</span
          >

          <ng-container *ngIf="devEnvironment">
            <span
              style="cursor: pointer"
              *ngIf="menu?.label == 'Dispatch Module'"
              (click)="dispatch = !dispatch"
              class="sidebar-menu-text"
              >{{ menu.label }}</span
            >
            <span
              style="margin-left: 20px !important"
              *ngIf="!dispatch && menu?.label == 'Dispatch Module'"
              style="font-size: 15px"
              >&#9660;</span
            >
            <span
              style="margin-left: 20px !important"
              *ngIf="dispatch && menu?.label == 'Dispatch Module'"
              style="font-size: 15px"
              >&#9650;</span
            >
          </ng-container>
        </a>
        <div
          style="padding: 20px"
          *ngIf="dispatch && menu?.label == 'Dispatch Module'"
        >
          <ng-container *ngFor="let children of menu['children']">
            <a
              [routerLink]="children.route"
              [queryParams]="children.queryParams"
              class="sidebar-menu-item"
              [routerLinkActive]="['active']"
              [routerLinkActiveOptions]="{ exact: true }"
              *ngIf="!children.legacy"
            >
              <span class="sidebar-menu-icon">
                <svg height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <use attr.xlink:href="{{ children.icon }}" />
                </svg>
              </span>
              <span class="sidebar-menu-text">{{ children.label }}</span>
            </a>
          </ng-container>
        </div>

        <a
          href="{{
            menu.actualRedirection
              ? menu.route +
                (menu.route.includes('?') ? '&' : '?') +
                'AuthToken=' +
                token
              : 'https://stgdev.parivesh.nic.in/compliance/api/complianceLogin?AuthToken=' +
                token +
                '&redirect_uri=' +
                menu.route +
                '&email=' +
                email
          }}"
          class="sidebar-menu-item"
          *ngIf="menu.legacy && !menu.compliance"
        >
          <span class="sidebar-menu-icon">
            <svg height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <use attr.xlink:href="{{ menu.icon }}" />
            </svg>
          </span>
          <span class="sidebar-menu-text">{{ menu.label }}</span>
        </a>
        <a
          href="{{
            devEnvironment
              ? 'https://stgdev.parivesh.nic.in/#/department/compliance?redirectUrl=https://stgdev.parivesh.nic.in/compliance/api/complianceLogin'
              : 'https://parivesh.nic.in/newupgrade/#/department/auth-redirect?redirectUrl=https://parivesh.nic.in/compliance/api/complianceLogin'
          }}"
          class="sidebar-menu-item"
          *ngIf="menu.legacy && menu.compliance"
        >
          <span class="sidebar-menu-icon">
            <svg height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <use attr.xlink:href="{{ menu.icon }}" />
            </svg>
          </span>
          <span class="sidebar-menu-text">{{ menu.label }}</span>
        </a>
      </ng-container>
      <!-- <a *ngIf="devEnvironment"  class="sidebar-menu-item"   routerLink="/campa/dashboard">
        <span class="sidebar-menu-icon">
          <svg height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <use attr.xlink:href="" />
          </svg>
        </span>
        <span class="sidebar-menu-text">CAMPA</span>
      </a> -->
    </div>
  </div>
  <div class="sidebar-menu-footer">
    <div class="sidebar-menu-profile">
      <span class="sidebar-menu-icon">
        <svg height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <use xlink:href="./assets/images/menu.svg#icon-user" />
        </svg>
      </span>
      <span class="sidebar-menu-text">
        <p class="mb-0">{{ user?.name_of_Entity || user?.name | titlecase }}</p>
        <span *ngIf="userRole$ | async as userRole"
          ><small>{{ userRole?.data.role }}</small></span
        >
      </span>
    </div>
    <a
      href="javascript:void(0);"
      class="sidebar-menu-item logout"
      (click)="onLogout()"
    >
      <span class="sidebar-menu-icon">
        <svg
          width="14"
          height="18"
          viewBox="0 0 14 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <use xlink:href="./assets/images/menu.svg#icon-logout" />
        </svg>
      </span>
      <span class="sidebar-menu-text">Logout</span>
    </a>
  </div>
</div>
